<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <!-- THANK YOU MODAL -->
    <el-dialog
      :show-close="false"
      :lock-scroll="false"
      :visible.sync="showPurchaseCompleted"
      class="dialog-md"
    >
      <h2 class="d-flex align-items-center justify-content-center">
        <i style="color: #009545" class="fa-solid fa-circle-check mr-2"></i>
        <div class="login-modal-text-box">
          <span>{{ $t("dashboard.thanks_for_purchase") }}</span>
        </div>
      </h2>
      <p v-show="counter != 0" class="wc-per">
        {{ $t("dashboard.plase_wait") }} {{ counter }}
        {{ $t("dashboard.seconds") }}
      </p>
      <p v-show="counter == 0" class="wc-per">The dialogue is about to close</p>
    </el-dialog>
    <!-- FREEMIUM ACTIVATED MODAL -->
    <el-dialog
      :show-close="false"
      :lock-scroll="false"
      :visible.sync="showFreemiumCompleted"
      class="dialog-md freemium_modal"
    >
      <h2 class="d-flex align-items-center justify-content-center">
        <div class="login-modal-text-box">
          <span>{{ $t("pricing.plans.freemium.welcome_modal.title") }}</span>
        </div>
      </h2>
      <p class="wc-per">
        {{ $t("pricing.plans.freemium.welcome_modal.description") }}
      </p>
      <ul>
        <li v-for="(feature, index) in features" :key="index">
          <i class="fa-solid fa-circle-check mr-2"></i>
          {{ $t(`pricing.plans.freemium.card.${feature.title}`) }}
        </li>
      </ul>
    </el-dialog>
    <DowngradePlan
      v-if="showDowngradeModal"
      :userLimits="userLimit"
    ></DowngradePlan>
    <FreemiumModal v-if="toolLocked || showFreemiumModal || outputLimit" />
    <SurveyModal
      v-if="hasTemporaryPWD || showSurveyModal"
      @submit="submitSurvey"
      :complete-profile="hasTemporaryPWD"
    />
    <FailedPaymentModal
      v-show="showFailedPaymentsModal"
      :show-modal="showFailedPaymentsModal"
      @close="showFailedPaymentsModal = false"
      ref="failedPaymentModal"
    />
    <ReactivateSubscriptionModal
      v-show="showReactivateSubscriptionModal"
      :show-modal="showReactivateSubscriptionModal"
      @close="showReactivateSubscriptionModal = false"
      ref="reactivateSubscriptionModal"
    />
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      v-if="user"
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot="links">
        <sidebar-item
          v-for="(linkItem, index) in filteredActiveSidebarLinks"
          :key="index"
          :link="transformLinkForAiArt(linkItem)"
          :locked="showLocker(linkItem)"
          :basicLocked="basicLocked(linkItem)"
          @click.native="workspaceRedirect(linkItem)"
        ></sidebar-item>
      </template>
    </side-bar>

    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import SidebarShare from "./SidebarSharePlugin";
import DowngradePlan from "src/pages/Pages/PaymentsCheckout/DowngradePlan.vue";
import FailedPaymentModal from "../Pages/PaymentsCheckout/FailedPaymentModal.vue";
import SurveyModal from "src/pages/Pages/PaymentsCheckout/SurveyModal.vue";
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import SidebarFixedToggleButton from "./SidebarFixedToggleButton.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import { getDarkMode } from "../../services/localService";
import { mapActions, mapGetters } from "vuex";
import axios from "@/axios";
import { Dialog } from "element-ui";
import sidebarLinksData from "@/jsonFiles/sidebarLinks.json";
import ReactivateSubscriptionModal from "../Pages/PaymentsCheckout/ReactivateSubscriptionModal.vue";
import { checkIfKeyExist } from "@/util/localStorageHandler";
import FreemiumModal from "src/pages/Components/Modal/FreemiumModal.vue";
import plans from "@/jsonFiles/plans.json";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition,
    SidebarShare,
    DowngradePlan,
    SurveyModal,
    [Dialog.name]: Dialog,
    FailedPaymentModal,
    ReactivateSubscriptionModal,
    FreemiumModal,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      showDowngradeModal: false,
      showPurchaseCompleted: false,
      counter: 0,
      showFailedPaymentsModal: false,
      showReactivateSubscriptionModal: false,
      showFreemiumCompleted: false,
    };
  },
  watch: {
    showPurchaseCompleted() {
      if (this.showPurchaseCompleted == false) {
        this.$store.dispatch("fetchWalletInfo");
      }
    },

    isFailedPaymentsProcessed(value) {
      this.performActionUntilPaymentIsProcessed();
    },

    userHasReactivationCode(value) {
      this.showReactivateSubscriptionModal = this.userHasReactivationCode;
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      userLimit: "getCheckUserLimit",
      isFailedPaymentsProcessed: "isFailedPaymentsProcessed",
      wallet: "walletInfoObj",
      userHasReactivationCode: "getUserHasReactivationCode",
      pref_lang: "getSelectedLanguage",
      toolLocked: "getToolLockedModal",
      outputLimit: "getOutputLimitModal",
      showFreemiumModal: "getShowModal",
      freemiumStatus: "getFreemiumStatus",
    }),
    showSurveyModal() {
      return this.user.show_survey_modal;
    },
    hasCustomTools() {
      return this.$store.getters.getTools.some((t) => t.is_custom);
    },
    companyJumboStyle() {
      return `margin-top: 200px;padding-top: 40px !important;`;
    },
    hasTemporaryPWD() {
      return this.user?.hasTemporaryPWD;
    },
    hasWorkspace() {
      return this.$store.getters.getHasWorkspace ?? false;
    },

    filteredActiveSidebarLinks() {
      let hasDividerBottom = false;

      // Filtra i link e gestisci il `dividerBottom` di workspace separatamente
      const filteredLinks = sidebarLinksData.sidebarLinks.filter((link) => {
        // Se è adminRoute e l'utente non è admin, escludilo
        if (link.adminRoute && !this.user.is_admin) {
          return false;
        }

        // Salva il valore di `dividerBottom` se il link è `/workspace/login` e ignoralo
        if (this.freemiumStatus && link.path === "/workspace/login") {
          if (link.dividerBottom) {
            hasDividerBottom = true;
          }
          return false;
        }

        // Se il link è nascosto per utenti demo e l'utente è demo, escludilo
        if (link.hideForDemoUser && this.wallet?.isDemoUser) {
          return false;
        }

        // Includi il link solo se è attivo
        return link.active;
      });
      // Trova il link con `path: "/orders"` e applica il `dividerBottom`
      const ordersLink = filteredLinks.find((link) => link.path === "/orders");
      if (hasDividerBottom && ordersLink) {
        ordersLink.dividerBottom = true;
      }
      return filteredLinks;
    },

    features() {
      return plans["freemium"]?.features || "";
    },
  },
  methods: {
    ...mapActions(["fetchUserInfo", "freemiumGenerationCredit"]),
    workspaceRedirect(link) {
      if (link.forceRedirect) {
        this.$router.push({ path: link.path, query: { forceRedirect: true } });
      } else {
        this.$router.push(link.path);
      }
    },
    toggleSidebar() {
      let bodyElementClasses = document.body.classList;
      let htmlElementClasses = document.documentElement.classList;
      bodyElementClasses.remove("overflow-no-scroll");
      htmlElementClasses.remove("overflow-no-scroll");
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initDarkMode() {
      let docClasses = document.body.classList;
      if (getDarkMode()) {
        docClasses.remove("white-content");
      } else {
        docClasses.add("white-content");
      }
    },

    startTimer() {
      this.counter = 5;
      setInterval(() => {
        if (this.counter != 0) {
          this.counter = this.counter - 1;
        } else {
          clearInterval();
          this.deleteParam("purchase-completed");
          this.showPurchaseCompleted = false;
        }
      }, 1000);
    },

    deleteParam(param) {
      const url = new URL(location);
      url.searchParams.delete(param);
      history.replaceState(null, null, url);
    },

    async submitSurvey(form) {
      this.$preloader.fadeIn();
      const gtmObj = {
        country: this.user.country,
        userId: this.user.id,
      };
      form.user_id = this.user.id;
      const response = await axios.post("features/survey/submit-survey", {
        ...form,
      });

      if (response.data.status == "success") {
        await this.fetchUserInfo();
        this.$preloader.fadeOut();
        if (this.$route.query.hasOwnProperty("purchase-completed")) {
          this.deleteParam("purchase-completed");
        }
        this.deleteParam("start-ft-flow");
        this.deleteParam("plan");
        this.$message({
          showClose: true,
          message: response.data.message,
          type: "success",
        });
        window.gtm.ec_survey_submitted({
          ...response.data.survey,
          ...gtmObj,
        });
      } else {
        this.$preloader.fadeOut();
        this.$message({
          showClose: true,
          message: response.data.message,
          type: "error",
        });
      }
    },

    hasUrlParams(param) {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      if (urlParams.has(param) && urlParams.get(param) == 1) {
        return true;
      }
      return false;
    },

    async performActionUntilPaymentIsProcessed() {
      this.showFailedPaymentsModal = !this.isFailedPaymentsProcessed
        ? true
        : false;
      if (this.showFailedPaymentsModal) {
        while (!this.isFailedPaymentsProcessed) {
          await this.sleep(500);
          await this.makeRequest();
        }
        this.showFailedPaymentsModal = false;
        window.location.reload();
      }
    },

    async makeRequest() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.$store.dispatch("checkFailedPayments");
          resolve();
        }, 10000);
      });
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    handleRedirectInDashboardForFailedPayments(event) {
      if (
        this.showFailedPaymentsModal &&
        !this.$refs.failedPaymentModal.$el.contains(event.target)
      ) {
        this.$router.go(this.$router.currentRoute);
      }
    },

    basicLocked(item) {
      return this.userLimit?.plan_type === "basic" && item?.basicLocker;
    },

    showLocker(item) {
      return !this.user.is_admin && this.wallet?.userIsFTFlow && item.locker;
    },
    transformLinkForAiArt(link) {
      if (link.path !== "https://ai-art.contents.ai") {
        return link;
      } else {
        return { ...link, path: window.aiArtUrl };
      }
    },

    sendHotjarUserInfo() {
      this.$store.dispatch("fetchWalletInfo").then(() => {
        const isFreeTrial = this.wallet?.userIsFTFlow;
        const pageLang = this.pref_lang;
        const plan = isFreeTrial ? "Free trial" : this.wallet?.planName;
        window.hj("identify", null, {
          plan: plan,
          language: pageLang,
        });
      });
    },
  },
  async created() {
    await this.$store.dispatch("checkFailedPayments");
    await this.$store.dispatch("checkForSubscriptionReactivationCode");
  },
  async mounted() {
    // this.initScrollbar();
    this.initDarkMode();
    await this.$store
      .dispatch("checkUserLimits")
      .then((_) => {
        if (this.userLimit.plan_type) {
          if (
            this.userLimit.brand_voices.exceeded ||
            this.userLimit.user_seats.exceeded ||
            this.userLimit.workspaces.exceeded
          ) {
            this.showDowngradeModal = true;
          }
        }
      })
      .catch((error) => console.error(error));
    if (this.$route.query.hasOwnProperty("start-ft-flow")) {
      this.deleteParam("purchase-completed");
    }
    if (
      this.$route.query.hasOwnProperty("purchase-completed") &&
      !this.$route.query.hasOwnProperty("start-ft-flow") &&
      this.hasTemporaryPWD
    ) {
      this.deleteParam("purchase-completed");
    }
    if (
      this.$route.query.hasOwnProperty("purchase-completed") &&
      !this.$route.query.hasOwnProperty("start-ft-flow") &&
      !this.hasTemporaryPWD
    ) {
      this.showPurchaseCompleted = true;
      this.startTimer();
    }

    if (this.$route.query.hasOwnProperty("start-ft-flow")) {
      window.gtm.ec_survey_viewed();
      this.deleteParam("start-ft-flow");
    }

    if (this.hasUrlParams("verified")) {
      window.gtm.sign_up_confirmation(this.user ?? []);
      this.deleteParam("verified");
    }

    if (this.hasUrlParams("freemium")) {
      this.showFreemiumCompleted = true;
      this.deleteParam("freemium");
    }

    if (this.showFailedPaymentsModal) {
      document.addEventListener(
        "click",
        this.handleRedirectInDashboardForFailedPayments,
      );
    }

    this.sendHotjarUserInfo();
    if (this.freemiumStatus) {
      this.freemiumGenerationCredit();
    }
  },
  beforeDestroy() {
    document.removeEventListener(
      "click",
      this.handleRedirectInDashboardForFailedPayments,
    );
  },
  beforeMount() {
    console.log(window.location.href);
    if (window.location.href.includes("macrocategory=human")) {
      this.$router.push("dashboard");
    }
  },
};
</script>

<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.freemium_modal {
  .el-dialog {
    display: flex;
    padding: 2.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    border-radius: 1rem;
    .el-dialog__header {
      display: none;
    }
  }

  h2 {
    span {
      color: var(--blu-004583, #004583);
      text-align: center;
      font-family: Lato;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 0.9375rem; /* 62.5% */
    }
  }
  .el-dialog__body {
    p {
      color: #004583 !important;
      font-size: 0.875rem !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 150%;
      text-align: left;
      word-wrap: break-word;
      word-break: break-word;
    }
  }

  ul {
    padding: 0;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    p {
      margin-bottom: 1rem;
    }
    li {
      color: #004583;
      list-style: none;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0.44rem;

      i {
        color: #1bb27a;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 900;
        line-height: 1rem; /* 123.077% */
      }
    }
  }
}
</style>
