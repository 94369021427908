const getDefaultState = () => {
  return {
    freemiumStatus: false,
    toolLocked: false,
    outputLimit: false,
    showModal: false,
    freemiumCredit: null,
    includedTools: null,
  };
};

const moduleFreemium = {
  state: getDefaultState(),
  getters: {
    getFreemiumStatus(state) {
      return state.freemiumStatus;
    },
    getToolLockedModal(state) {
      return state.toolLocked;
    },
    getOutputLimitModal(state) {
      return state.outputLimit;
    },
    getShowModal(state) {
      return state.showModal;
    },
    getFreemiumCredits(state) {
      return state.freemiumCredit;
    },
    getIncludedTools(state) {
      return state.includedTools;
    },
  },
  mutations: {
    setFreemiumStatus(state, data) {
      state.freemiumStatus = data;
    },
    setToolLockedModal(state, data) {
      state.toolLocked = data;
    },
    setOutputLimitModal(state, data) {
      state.outputLimit = data;
    },
    setShowModal(state, data) {
      state.showModal = data;
    },
    setFreemiumCredits(state, data) {
      state.freemiumCredit = data;
    },
    setIncludedTools(state, data) {
      state.includedTools = data;
    },
  },
  actions: {
    freemiumGenerationCredit({ commit, getters }) {
      console.log(
        getters.walletInfoObj.freemium_config,
        getters.walletInfoObj.freemium_config.content_generation_per_day -
          getters.walletInfoObj.freemium_config.content_generations_counter,
      );
      const freemiumCredits =
        getters.walletInfoObj.freemium_config.content_generation_per_day -
        getters.walletInfoObj.freemium_config.content_generations_counter;
      if (!freemiumCredits) {
        commit("setOutputLimitModal", true);
        commit("setShowModal", true);
      }
      commit("setFreemiumCredits", freemiumCredits);
      commit(
        "setIncludedTools",
        getters.walletInfoObj.freemium_config.included_tools,
      );
    },
  },
};

export default moduleFreemium;
