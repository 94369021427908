<template>
  <div class="modal_freemium">
    <el-dialog
      :title="$t(`pricing.plans.freemium.modal.${titleLabel}`)"
      :visible.sync="showModal"
      class="dialog-md"
    >
      <h4>{{ $t(`pricing.plans.freemium.modal.${subTitleLabel}`) }}</h4>
      <div class="list">
        <p>{{ $t(`pricing.plans.freemium.modal.upgrade_info`) }}</p>
        <ul>
          <li>
            <i class="fa-sharp fa-solid fa-circle-check"></i>
            {{ $t(`pricing.plans.freemium.modal.upgrade_info_list_item_1`) }}
          </li>
          <li>
            <i class="fa-sharp fa-solid fa-circle-check"></i>
            {{ $t(`pricing.plans.freemium.modal.upgrade_info_list_item_2`) }}
          </li>
          <li>
            <i class="fa-sharp fa-solid fa-circle-check"></i>
            {{ $t(`pricing.plans.freemium.modal.upgrade_info_list_item_3`) }}
          </li>
          <li>
            <i class="fa-sharp fa-solid fa-circle-check"></i>
            {{ $t(`pricing.plans.freemium.modal.upgrade_info_list_item_4`) }}
          </li>
          <li>
            <i class="fa-sharp fa-solid fa-circle-check"></i>
            {{ $t(`pricing.plans.freemium.modal.upgrade_info_list_item_5`) }}
          </li>
        </ul>
      </div>
      <div class="d-flex">
        <base-button
          class="btn_secondary_version w-100 ml-1"
          native-type="submit"
          type="primary"
          @click="closemodal"
        >
          {{ $t(`pricing.plans.freemium.modal.keep_exploring_cta`) }}
        </base-button>
        <base-button
          class="btn__generate w-100 ml-1 pl-3 pr-3"
          native-type="submit"
          type="primary"
          @click="redirectToPricing"
        >
          <i class="fa-solid fa-circle-bolt mr-2"></i>
          {{ $t(`pricing.plans.freemium.modal.upgrade_cta`) }}
        </base-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Dialog } from "element-ui";
import { mapGetters } from "vuex";

export default {
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      toggleModalVisible: true,
    };
  },
  methods: {
    redirectToPricing() {
      this.$router.push({ path: "/pricing" });
    },
    closemodal() {
      this.$store.commit("setToolLockedModal", false);
      this.$store.commit("setOutputLimitModal", false);
      this.$store.commit("setShowModal", false);
    },
  },
  computed: {
    ...mapGetters({
      toolLocked: "getToolLockedModal",
      outputLimit: "getOutputLimitModal",
      showModal: "getShowModal",
    }),
    titleLabel() {
      if (this.toolLocked) {
        return "tool_locked_title";
      }
      if (this.outputLimit) {
        return "no_credit_title";
      }
      return "feature_locked_title";
    },

    subTitleLabel() {
      if (this.toolLocked) {
        return "tool_locked_subtitle";
      }
      if (this.outputLimit) {
        return "no_credit_subtitle";
      }
      return "feature_locked_subtitle";
    },
  },
};
</script>
<style lang="scss">
.modal_freemium {
  text-align: center;

  .el-dialog__wrapper:after {
    display: none;
  }

  .el-dialog__title {
    color: #004583;
    font-family: Lato;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.9375rem; /* 75% */
    margin-bottom: 0.5rem;
  }

  .el-dialog__header {
    padding: 1.5rem;
    padding-bottom: 0;
  }

  .el-dialog__body {
    padding: 1.5rem;
  }

  h4 {
    color: #566a7f !important;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2.5rem;
  }

  .list {
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    margin-bottom: 2.5rem;

    p {
      color: #566a7f;
      font-family: Lato;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 1rem;
      text-align: left;
    }

    ul {
      padding: 0;
      margin: 0;
    }
    li {
      list-style: none;
      margin-bottom: 0.5rem;
      color: #566a7f;
      text-align: left;
      font-family: Lato;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      i {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
