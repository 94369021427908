<template>
  <div
    v-if="
      walletInfoObj?.activePlanPackage?.plan_type === 'basic' ||
      walletInfoObj?.freemium_config
    "
    class="wallet"
  >
    <div v-if="showCreditsPanel" class="wallet-info">
      <span class="wallet-title">{{
        !walletInfoObj.freemium_config
          ? "Credits"
          : $t("pricing.plans.freemium.counter_title")
      }}</span>
      <SidebarWalletCell
        v-if="!walletInfoObj.freemium_config"
        :title="'AI'"
        :credits="
          walletInfoObj?.activePlanPackage?.plan_type === 'basic'
            ? walletInfoObj?.aiCredits
            : 'Unlimited'
        "
        :paymentUrl="walletInfoObj?.isAiCreditsUnlimited ? '' : this.paymentUrl"
      />

      <div
        v-if="!isDemoUser && !walletInfoObj.freemium_config"
        class="divider"
      ></div>
      <SidebarWalletCell
        v-if="walletInfoObj?.extraCredits > 0 && !walletInfoObj.freemium_config"
        :title="'Extra'"
        :credits="walletInfoObj?.extraCredits"
        :paymentUrl="walletInfoObj?.isAiCreditsUnlimited ? '' : this.paymentUrl"
      />
      <SidebarWalletCell
        v-if="walletInfoObj?.freemium_config"
        :freemium="walletInfoObj?.freemium_config ? true : false"
        :credits="freemiumCredits"
      />
      <div
        v-if="
          walletInfoObj?.extraCredits > 0 || !walletInfoObj?.freemium_config
        "
        class="divider"
      ></div>
      <SidebarUpgradeButton
        v-if="showUpgradeButton"
        :paymentUrl="this.paymentUrl"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SidebarWalletCell from "./SidebarWalletCell";
import SidebarUpgradeButton from "./SidebarUpgradeButton";
export default {
  name: "SidebarWallet",
  components: {
    SidebarWalletCell,
    SidebarUpgradeButton,
  },
  methods: {
    ...mapActions(["fetchWalletInfo"]),
  },
  async created() {
    await this.fetchWalletInfo();
  },
  computed: {
    ...mapGetters({
      walletInfoObj: "walletInfoObj",
      freemiumCredits: "getFreemiumCredits",
    }),

    showCreditsPanel() {
      if (
        this.isCreationDoseProxy ||
        this.isOysterProxy ||
        this.isCoderBlock ||
        this.isKeyOne ||
        this.isComunicare ||
        this.isTif ||
        this.isEwinberg ||
        this.isWebAds ||
        this.isZummum ||
        this.isUserbot ||
        this.isDeltaPictures ||
        this.isSensoSan
      ) {
        return false;
      }
      return (
        (this.walletInfoObj && this.walletInfoObj.aiCredits !== 0) ||
        this.walletInfoObj?.freemium_config
      );
    },
    userIsFTFlow() {
      return this.walletInfoObj && this.walletInfoObj.userIsFTFlow;
    },

    isCreationDoseProxy() {
      return this.walletInfoObj?.user_master_email.includes("@creationdose.com")
        ? true
        : false;
    },

    isOysterProxy() {
      return this.walletInfoObj?.user_master_email.includes("@oystermedia.it")
        ? true
        : false;
    },

    isCoderBlock() {
      return this.walletInfoObj?.user_master_email.includes("@coderblock.com")
        ? true
        : false;
    },

    isKeyOne() {
      return this.walletInfoObj?.user_master_email.includes("@key-one")
        ? true
        : false;
    },

    isComunicare() {
      return this.walletInfoObj?.user_master_email.includes("@comunicare")
        ? true
        : false;
    },

    isTif() {
      return this.walletInfoObj?.user_master_email.includes(
        "@theinnovationfactory",
      )
        ? true
        : false;
    },

    isEwinberg() {
      return this.walletInfoObj?.user_master_email.includes("@ewinberg")
        ? true
        : false;
    },

    isWebAds() {
      return this.walletInfoObj?.user_master_email.includes("@webads")
        ? true
        : false;
    },

    isZummum() {
      return this.walletInfoObj?.user_master_email.includes("@zummum")
        ? true
        : false;
    },

    isUserbot() {
      return this.walletInfoObj?.user_master_email.includes("@userbot")
        ? true
        : false;
    },

    isDemoUser() {
      return this.walletInfoObj.isDemoUser;
    },

    isDeltaPictures() {
      return this.walletInfoObj?.user_master_email.includes("@deltapictures")
        ? true
        : false;
    },

    isSensoSan() {
      return this.walletInfoObj?.user_master_email.includes("@sensosan")
        ? true
        : false;
    },
    paymentUrl() {
      return "/pricing";
    },
    showUpgradeButton() {
      if (this.walletInfoObj?.isDemoUser) {
        return false;
      }

      return (
        !this.walletInfoObj?.freemium_config ||
        !this.walletInfoObj?.activePlanPackage ||
        this.walletInfoObj?.activePlanPackage?.plan_type !== "advanced" ||
        this.walletInfoObj?.activePlanPackage?.plan_type !== "pro" ||
        (this.walletInfoObj?.activePlanPackage?.plan_type === "advanced" &&
          !this.walletInfoObj?.yearlySub) ||
        (this.walletInfoObj?.activePlanPackage?.plan_type === "pro" &&
          !this.walletInfoObj?.yearlySub)
      );
    },
  },
};
</script>
<style>
.wallet {
  position: sticky;
  z-index: 999;
}
.wallet-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 10px 10px 0 0;
}

.white-content .wallet-info {
  background-color: rgba(15, 37, 123, 1);
}
.wallet-title {
  color: #eceef1;
  text-align: center;
  font-family: Lato;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 9px; /* 100% */
  letter-spacing: -0.09px;
  text-transform: uppercase;
  margin-bottom: 12px;
  width: 100%;
}
.sidebar:hover .wallet-info {
  padding-left: 14px;
  padding-right: 14px;
}
.sidebar:hover .wallet-title {
  text-align: center;
  font-size: 0.75rem;
}

@media (max-width: 991px) {
  .wallet-info {
    padding-left: 14px;
    padding-right: 14px;
  }
  .wallet-title {
    font-size: 12px;
  }
}
</style>
