export const googleTagManager = {
  sign_up(data) {
    dataLayer.push({
      event: "sign_up",
      industry: data?.industry,
      country: data?.country,
    });
  },

  login(user) {
    dataLayer.push({
      event: "login",
      customer_type: user?.customer_type,
      user_id: user?.id,
      email: user?.email ?? "",
      full_name: user?.name ?? "",
      industry: user?.industry_name,
      country: user?.country ?? "",
      signup_utm_source: user?.utm_source ?? "",
      signup_utm_medium: user?.utm_medium ?? "",
      signup_utm_campaign: user?.utm_campaign ?? "",
    });
  },

  sign_up_confirmation(user) {
    dataLayer.push({
      event: "sign_up_confirmation",
      customer_type: user?.customer_type,
      user_id: user?.id,
      email: user?.email ?? "",
      full_name: user?.name ?? "",
      country: user?.country ?? "",
    });
  },

  tool_viewed(data) {
    dataLayer.push({
      event: "tool_viewed",
      tool_name:
        (data?.gtag_service_group ?? "") +
        " - " +
        (data?.gtag_service_name ?? ""),
      tool_category: data?.gtag_service_group ?? "",
    });
  },

  tool_order_requested(data) {
    dataLayer.push({
      event: "tool_order_requested",
      tool_name:
        (data?.gtag_service_group ?? "") +
        " - " +
        (data?.gtag_service_name ?? ""),
      tool_category: data?.gtag_service_group ?? "",
      cost_for_usage: (data?.human_credits ?? 0) * 0.01,
    });
  },

  tool_order_completed(data) {
    dataLayer.push({
      event: "tool_order_completed",
      tool_name:
        (data?.gtag_service_group ?? "") +
        " - " +
        (data?.gtag_service_name ?? ""),
      tool_category: data?.gtag_service_group ?? "",
      cost_for_usage: (data?.human_credits ?? 0) * 0.01,
    });
  },

  tool_error(tool, category, error) {
    dataLayer.push({
      event: "tool_error",
      tool_name: (category ?? "") + " - " + (tool ?? ""),
      tool_category: category ?? "",
      cost_for_usage: 0,
      error_type: error ?? "",
    });
  },

  ec_view_item(type, category, currency, pricingPlans) {
    let items = [];

    if (pricingPlans != null) {
      const extraPlans = [
        {
          item_id: "USER_SEATS",
          item_name: "Extra User Seats",
          item_category: "Monthly",
          item_category2: "AI",
          price: "10.00",
        },
        {
          item_id: "EXTRA_CREDITS",
          item_name: "Extra credits",
          item_category: "recharge",
          item_category2: "AI",
          price: "0.30",
        },
      ];
      pricingPlans.forEach((item) => {
        items.push({
          item_id: item.type,
          item_name: item.name,
          item_category: category,
          item_category2: type,
          price: item.prices[0].amount.toFixed(2),
        });
      });

      items = [...items, ...extraPlans];
    } else {
      items.push({
        item_id: "human",
        item_name: "One-time recharge",
        item_category: "recharge",
        item_category2: type,
        price: "undefined",
      });
    }

    dataLayer.push({
      event: "view_item",
      currency: currency,
      items: items,
    });
  },

  ec_add_to_wishlist(currency) {
    dataLayer.push({
      event: "add_to_wishlist",
      currency: currency,
      items: [
        {
          item_id: "PRSZD",
          item_name: "Personalized plan",
          item_category: "personalized",
          item_category2: "AI",
          price: 0,
          quantity: 1,
        },
      ],
    });
  },

  ec_step_checkout(event, currency, price, currentPlan) {
    let items = [];
    let category = currentPlan.yearly ? "Yearly" : "Monthly";

    if (currentPlan.type != "one-shot-package") {
      items.push({
        item_id: currentPlan.type,
        item_name: currentPlan.name,
        item_category: category,
        item_category2: "AI",
        price: price,
        quantity: 1,
      });
    } else {
      items.push({
        item_id: "human",
        item_name: "One-time recharge",
        item_category: "recharge",
        item_category2: "Human",
        price: price,
        quantity: 1,
      });
    }

    dataLayer.push({
      event: event,
      currency: currency,
      items: items,
    });
  },

  ec_start_trial(data) {
    dataLayer.push({
      event: "start_trial",
      customer_type: "in_trial",
      user_type: data.user_type, // private o company
      user_id: data.user_id,
      email: data.email,
      full_name: data.full_name,
      signup_utm_source: data.signup_utm_source,
      signup_utm_medium: data.signup_utm_medium,
      signup_utm_campaign: data.signup_utm_campaign,
      signup_utm_content: data.signup_utm_content,
    });
  },

  ec_start_freemium(data) {
    dataLayer.push({
      event: "start_freemium",
      customer_type: "freemium_user",
      user_type: data.user_type, // private o company
      user_id: data.user_id,
      email: data.email,
      full_name: data.full_name,
      signup_utm_source: data.signup_utm_source,
      signup_utm_medium: data.signup_utm_medium,
      signup_utm_campaign: data.signup_utm_campaign,
      signup_utm_content: data.signup_utm_content,
    });
  },

  ec_survey_viewed() {
    dataLayer.push({
      event: "survey_viewed",
    });
  },

  ec_survey_submitted(data) {
    dataLayer.push({
      event: "survey_submitted",
      role: data.jobRole,
      company_size: data.companySize,
      industry: data.industry,
      country: data.country,
      content_type: data.contentType,
      previous_experience: data.howProficientAre,
      user_id: data.userId,
    });
  },

  user_identified(data) {
    dataLayer.push({
      event: "user_identified",
      user_id: data.user_id,
      email: data.email,
      full_name: data.full_name,
      country: data.country,
      customer_type: data.customer_type,
      signup_utm_source: data.signup_utm_source,
      signup_utm_medium: data.signup_utm_medium,
      signup_utm_campaign: data.signup_utm_campaign,
      signup_utm_content: data.signup_utm_content,
      newsletter_subscriber: "NewsletterSubscriber",
      industry: data.industry,
    });
  },
};
